import { useState } from 'react';
import logo from './pub/logo.png';
import li from './pub/in.png';
import git from './pub/giit.png';
import './App.css';

import 'bootstrap/dist/css/bootstrap.css';

function Projects() {


  return (
    <body>
        <div class="row">
            <div class="column">
                <div class="gallery">
                    <a href="3.309-project.html">
                        <img src="pub\articles\Block.png" alt="309 Project" width="80%" />
                    </a>
                    <div class="desc">Backend App Development</div>
                </div>
            </div>
            <div class="column">
                <div class="gallery">
                    <a href="4.mini-feistel.html">
                        <img src="pub\articles\mini_block.PNG" alt="Mini-Feistel" width="80%" />
                    </a>
                    <div class="desc">Mini-Feistel</div>
                </div>
                
            </div>
        </div>
        <div class="row">
            <div class="column">
                <div class="gallery">
                    <a href="2.infix2postfix.html">
                        <img src="pub\articles\Infix2Postfix.png" alt="Infix2Postfix" width="500" height="500" />
                    </a>
                    <div class="desc">Infix to Postfix</div>
                </div>
            </div>
            <div class="column">
                <div class="gallery">
                    <a href="7.senior.html">
                        <img src="pub\articles\helics.png" alt="Senior Project" width="500" height="500" />
                    </a>
                    <div class="desc">Senior Project</div>
                </div>
                
            </div>
        </div>
        <div class="row">
            <div class="column">
                <div class="gallery">
                    <a href="1.381-project.html">
                        <img src="pub\articles\multi-381.webp" alt="381 Project" width="80%" />
                    </a>
                    <div class="desc">Multi-cycle Processor Project</div>
                </div>
            </div>
            <div class="column">
            </div>
        </div>
        <h2>Hobbies</h2>
        <div class="row">
            <div class="column">
                <div class="gallery">
                    <a href="6.photo-album.html">
                        <img src="pub\articles\photo-album\17.JPG" alt="Photo Album" width="80%" />
                    </a>
                    <div class="desc">Photo Album</div>
                </div>
            </div>
            <div class="column">
                <div class="gallery">
                    <a href="5.cardinal-eats.html">
                        <img src="pub\articles\cardinaleats2.png" alt="Cardinal Eats" width="80%" />
                    </a>
                    <div class="desc">Cardinal Eats</div>
                </div>
            </div>
        </div>
        <div class="spacer"></div>
    </body>
  );
}

export default Projects;

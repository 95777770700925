import { useState } from 'react';
import logo from './pub/logo.png';
import li from './pub/in.png';
import git from './pub/giit.png';
import './App.css';
import Projects from './projects';
import 'bootstrap/dist/css/bootstrap.css';

function Home() {

  return (
    <div className="padded row">
    <div className="col-sm-4">
        
        <img src={logo} style={{padding: "1%",width: "80%", margin: "auto", display:"block"}} />
        <hr style={{width:"90%", margin: "auto"}} />
        <h3>Current projects I am working on</h3>
        <hr style={{width:"90%", margin: "auto"}} />
        <h4><a href="7.senior.html">Senior Project</a></h4>
        <h4><a href="5.cardinal-eats.html">Cardinal Eats Club President</a></h4>

    </div>
    <div className="col-sm-8 d-flex align-items-center">
        <span>
            <p className='padded'>
                <h4>I am a senior in Cyber Security Engineering at Iowa State University. I am interested in Engineering cyber systems and Forensic Investigations. I just completed working as a Security Intern at Principal Financial Group working on "Blue Team" Engineering work.</h4>
                <br></br>
                <h3><a href="https://templeton.pro\pub\TempletonResumeSeptember2023.pdf">Here is a link to my resume</a></h3>
                <br></br>
                <h4>Thanks for taking a look!</h4>
            </p>
        </span>
    </div>
    
  </div>
  );
}

export default Home;

import { useState } from 'react';
import logo from './pub/logo.png';
import li from './pub/in.png';
import git from './pub/giit.png';
import './App.css';
import Projects from './projects'
import Home from './home';
import 'bootstrap/dist/css/bootstrap.css';
import Documents from './documents';
function Main() {


  const [whichPage, setWhichPage] = useState(1);



  const handleClick = (e) => {
    setWhichPage(e.target.id)
    console.log(whichPage);
  };

  return (
    <div className='body'>
      <br />
      <div className='header'>
        <div className='row'>
          <a onClick={() => setWhichPage(1)} className='col-sm-2 headCols firstCol hov'>
            <u>
             Home
            </u>
          </a>
          <a onClick={() => setWhichPage(2)} className='col-sm-2 headCols hov'>
            Projects
          </a>
          <a onClick={() => setWhichPage(3)} className='col-sm-2 headCols hov'>
            Resume
          </a>
          <a onClick={() => setWhichPage(4)} className='col-sm-2 headCols lastCol hov'>
            Documents
          </a>
        </div>
      </div>
      <main>
        {
          (() => {
            if(whichPage===1) {
              return <Home />
            } 
            else if (whichPage === 2) {
              return <Projects />
            } 
            else if (whichPage === 3) {
              return (
                <div>
                  <iframe src="./pub/TempletonResumeSeptember2023.pdf" width="80%" height="100%" />
                </div>
              );
            
            } 
            else if (whichPage === 4) {
              return <Documents />
            } 
            else {
              return ( <h1>ERROR</h1> )
            }
          })()  
        }
      </main>
      
      <div className='footer'>
        <div className='row'>
            <a className='col-sm-2'>
            </a>
            <a onClick={() => setWhichPage(1)} className='col-sm-2 headCols firstFoot hov '>
                <u>
                    Home
                </u>
            </a>
            <a onClick={() => setWhichPage(2)} className='col-sm-2 headCols hov'>
              Projects
            </a>
            <a onClick={() => setWhichPage(3)} className='col-sm-2 headCols hov'>
              Resume
            </a>
            <a onClick={() => setWhichPage(4)} className='col-sm-2 headCols hov'>
              Documents
            </a>
        </div>
        <div className='row'>
            <a className='col-sm-4'>
            </a>
            <a href="mailto:jstemps02@gmail.com" className='col-sm-4 headCols hov small'>
              <h3>Email Me</h3>
            </a>
           
        </div>
        <div className='row'>

          <div className='col-md-4'></div>
          <a className='col-md-2 headCols hov' target="_blank" href="https://www.github.com/jstemps">
              <img src={git} alt="GitHub" width="50px" />
          </a>
          <a className='col-md-2 headCols lastCol hov' target="_blank" href="https://www.linkedin.com/in/justin-templeton/">
              <img src={li} alt="LinkedIn" width="50px" />
          </a>
         

        </div>  
      </div>

    </div>
  );
}

export default Main;

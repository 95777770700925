import { useState } from 'react';
import logo from './pub/logo.png';
import li from './pub/in.png';
import git from './pub/giit.png';
import './App.css';
import Projects from './projects';
import 'bootstrap/dist/css/bootstrap.css';

function Documents() {

  return (
    <div class="DocList">
      <a title="This takes you right to my resume" href="https://templeton.pro\pub/TempletonResumeAugust2023.pdf">Resume</a>
      <br></br>
      <a title="An essay about my personal ethics and how they correlate into cyber security" href="https://www.templeton.pro\pub\Ethics.pdf">Personal Ethics</a>
      <br></br>
      <a title="A short summary of my experience at Iowa State University" href="https://templeton.pro\pub/Cumulative_Reflection.pdf">Cumulative Reflection</a>
      <br></br>
      <a title="My thoughts on how General Education Courses helped my development" href="https://templeton.pro\pub/General_Education_Reflection.pdf">General Education Reflection</a>
    </div>
  );
}

export default Documents;
